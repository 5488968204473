<template>
  <div class="sctp-bg-white">
    <template v-if="status && status.identityauth && status.authFlag === 1">
      <div class="sctp-pad-tb20 sctp-pad-lr20">
        <el-form ref="shopInfoForm" class="shopInfoForm pr" :model="status" label-width="100px">
          <div class="pa" style="right: 0;top: 0;">
            <el-button @click="goPage(`/shopinfo`)" type="primary" size="small">重新编辑</el-button>
            <el-button @click="openPage(`/service-provider/view/${status.shopId}`)" size="small">店铺主页
            </el-button>
          </div>
          <el-form-item prop="name" label="店铺名称" class="custom-input-width">
            <div>{{ status.shopName }}
              <el-tag size="small" type="success">已认证</el-tag>
            </div>
          </el-form-item>
          <el-form-item prop="logo" label="店铺Logo" class="custom-input-width">
            <el-image
              style="width: 100px; height: 100px"
              :src="status.shopLogo"
              fit="cover"
            ></el-image>
          </el-form-item>
          <el-form-item prop="mainBusiness" label="主营业务">
            <div class="rich-text" style="padding-top: 8px;" v-html="status.mainBusiness"></div>
          </el-form-item>
          <el-form-item prop="principal" label="店铺负责人" class="custom-input-width">
            <div>{{ status.principal }}</div>
          </el-form-item>
          <el-form-item prop="phone" label="联系电话" class="custom-input-width">
            <div>{{ status.phone }}</div>
          </el-form-item>
          <el-form-item class="custom-input-width" label="QQ号码" prop="qqNumber">
            <div>
              <span>{{ status.qqCode }}</span>
              <div class="auth_tips_wrap">
                <template v-if="status.qqAuth === 0">
                  <i class="el-icon-info fz-20"></i><span>待验证</span>
                </template>
                <template v-if="status.qqAuth === 1">
                  <i class="el-icon-success sctp-color-main fz-20"></i><span>已验证</span>
                </template>
                <template v-if="status.qqAuth === 2">
                  <i class="el-icon-error fz-20"
                     style="color: #F56C6C;"></i><span>验证未通过，请重新输入QQ号</span>
                </template>
                <template v-if="status.qqAuth === 3">
                  <i class="el-icon-warning fz-20"></i><span>待用户接收QQ邮箱验证</span>
                </template>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="custom-input-width" label="微信号码" prop="wxNumber">
            <div>
              <span>{{ status.wxCode }}</span>
              <div class="auth_tips_wrap">
                <template v-if="status.wxAuth === 0">
                  <i class="el-icon-info fz-20"></i><span>待验证</span>
                </template>
                <template v-if="status.wxAuth === 1">
                  <i class="el-icon-success sctp-color-main fz-20"></i><span>已验证</span>
                </template>
                <template v-if="status.wxAuth === 2">
                  <i class="el-icon-error fz-20"
                     style="color: #F56C6C;"></i><span>{{ status.wxAuthMemo }}</span>
                </template>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="微信二维码" prop="wxQrcode">
            <template v-if="status && status.wxQrcode">
              <div style="display: flex;align-items: start;">
                <el-image
                  :src="status.wxQrcode"
                  style="width: 150px; height: 150px"
                  fit="cover"
                ></el-image>
                <div class="auth_tips_wrap">
                  <template v-if="status.wxQrcodeAuth === 0">
                    <i class="el-icon-info fz-20"></i><span>待验证</span>
                  </template>
                  <template v-if="status.wxQrcodeAuth === 1">
                    <i class="el-icon-success sctp-color-main fz-20"></i><span>已验证</span>
                  </template>
                  <template v-if="status.wxQrcodeAuth === 2">
                    <i class="el-icon-error fz-20"
                       style="color: #F56C6C;"></i><span>{{ status.wxAuthMemo }}</span>
                  </template>
                </div>
              </div>

            </template>
          </el-form-item>
          <el-form-item prop="area" label="经营地址" class="custom-input-width">
            <el-cascader
              disabled
              :options="area"
              v-model="status.area"></el-cascader>
          </el-form-item>
          <el-form-item prop="addressDetail" label="详细地址" class="custom-input-width">
            <div>{{ status.addressDetail }}</div>
          </el-form-item>
          <el-form-item prop="introduce" label="店铺介绍">
            <div class="rich-text" style="padding-top: 8px;" v-html="status.introduce"></div>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-else>
      <el-card>
        <div class="">
          <div class="slider-card-title">
            店铺认证
          </div>
          <el-alert
            class="mg-t15"
            type="info"
            :closable="false"
            description="商家完成店铺入驻，开通网上店铺，可以发布出售源码信息；发布出售设计原稿信息；发布出售技术文档；发布技术服务，成为技术服务商。"
            show-icon>
          </el-alert>
        </div>
        <div class="sctp-pad-tb20 sctp-pad-lr20">
          您的店铺还未认证，请完成以下任务，开通店铺！
        </div>
        <!-- 未激活成功 -->
        <div class="sctp-pad-l20 sctp-pad-r20 pad-b20">
          <el-card shadow="always">
            <div class="sctp-flex sctp-flex-sb">
              <div class="card-content-text">
                <template>
                  <div class="fz-16">实名认证</div>
                  <div>
                    完成注册账号的实名认证，确保身份合法有效，实名认证类型分为个人认证、企业认证、个体工商户认证，不同的类型需要提供不同的认证信息，提交实名认证后需要后台审核通过。
                  </div>
                </template>
                <template v-if="status && status.authResult === 2">
                  <span class="sctp-lh-normal mg-l10 fc-normal">非常抱歉，您的实名认证审核未通过，主要原因：{{ status.revokereason }}，请修改后重新提交</span>
                </template>
              </div>
              <div class="sctp-flex sctp-flex-aic">
                <template v-if="status">
                  <template v-if="status.authResult === 0 && status.identityauth === false">
                    <a>
                      <el-button size="small" type="primary">审核中</el-button>
                    </a>
                  </template>
                  <template v-if="status.authResult === 1 && status.identityauth === true">
                    <a>
                      <el-button size="small">已完成</el-button>
                    </a>
                  </template>
                  <template v-if="status.authResult === 2">
                    <router-link to="/user/userInfo/verified">
                      <el-button size="small" type="primary">审核不通过</el-button>
                    </router-link>
                  </template>
                  <template v-if="status.authResult === 3">
                    <router-link to="/user/userInfo/verified">
                      <el-button size="small" type="primary">去完成</el-button>
                    </router-link>
                  </template>
                </template>
              </div>
            </div>
          </el-card>
          <el-card shadow="always">
            <div class="sctp-flex sctp-flex-sb">
              <div class="card-content-text">

                <template>
                  <div class="fz-16">店铺信息</div>
                  <div>完善您的店铺信息，确保店铺合法有效，让用户更了解并联系您，提交店铺信息需要后台审核通过。24小时之内审核完成。</div>
                </template>
                <template  v-if="status && status.shop_auth === 2">
                  <span class="sctp-lh-normal mg-l10 fc-normal">非常抱歉，您的店铺审核未通过,主要原因：{{ status.authMemo }}，请修改后重新提交</span>
                </template>
              </div>
              <div class="sctp-flex sctp-flex-aic">
                <template v-if="true">
                  <template v-if="status.shop_auth===0">
                    <el-button type="primary" size="small" @click="ifrealname(status.identityauth)">
                      去完成
                    </el-button>
                  </template>
                  <template v-else-if="status.shop_auth === 1">

                    <div>
                      <el-button size="small">已完成</el-button>
                      <el-button type="primary" size="small"
                                 @click="ifrealname(status.identityauth)">重新编辑
                      </el-button>
                    </div>
                  </template>
                  <template v-else-if="status.shop_auth === 2">
                    <el-button type="primary" size="small"
                               @click="ifrealname(status.identityauth)">重新提交
                    </el-button>
                  </template>
                  <template v-else-if="status.shop_auth === 3">
                    <div class="">
                      <div>
                        <el-button type="primary" size="small"
                                   @click="ifrealname(status.identityauth)">店铺预览
                        </el-button>
                        <el-button type="primary" size="small"
                                   @click="ifrealname(status.identityauth)">重新编辑
                        </el-button>
                      </div>
                      <div class="sctp-tc sctp-mar-t5">
                        审核中，请等待（已过 {{ elapsedTime }}）
                      </div>
                    </div>
                  </template>

                </template>
              </div>
            </div>
          </el-card>
        </div>
      </el-card>
    </template>
  </div>
</template>

<script>
import area from '../../resources/js/area'
import {shop} from "@/apis";
import DateUtil from "@/resources/js/dateutil";

const config = require('../../resources/js/config');
export default {
  name: 'shopIndex',
  components: {},
  data() {
    return {
      area: area.options,
      status: {
        authResult: null,
        identityauth: null,
        shop_auth: null,
        qqAuth: null,
        wxAuth: null,
        wxAuthMemo: null,
        wxQrcodeAuth: null,
        area: null
      },
      elapsedTime: null,
    }
  },
  destroyed() {
    // 清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    goCertification() {
      let {user} = this;
      if (!user) {
        return;
      }
      if (user.idCardAuthFlag !== 1) {
        if (user.userType === 3) {
          return;
        }
        if (user.userType === 2 || user.userType === 4) {
          // 跳转企业认证
          this.goPage('/user/userinfo/businessverified')
        } else if (user.userType === 1) {
          // 跳转个人认证
          this.goPage('/user/userinfo/verified')
        }
      }
    },
    getActiveStatus() {
      shop.authenticationStatus({
        userId: this.user.userId
      }).then(res => {
        console.log("=====================");
        const {data = {}, user: {authResult, identityauth, shop_auth,revokereason}} = res;
        console.log(data)
        this.status.authResult = authResult;
        this.status.identityauth = identityauth;
        this.status.shop_auth = shop_auth;
        this.status.revokereason = revokereason;
        if (null!=data){
          this.status.authMemo = data.authMemo;
          this.status.qqAuth = data.qqCodeAuthFlag;
          this.status.wxAuth = data.wxCodeAuthFlag;
          this.status.wxAuthMemo = data.wxQrCodeAuthMemo;
          this.status.wxQrcodeAuth = data.wxQrCodeAuthFlag;
          const areaArray = [];
          if (data.provinceCode) {
            areaArray.push(Number(data.provinceCode));
          }
          if (data.cityCode) {
            areaArray.push(Number(data.cityCode));
          }
          if (data.countyCode) {
            areaArray.push(Number(data.countyCode));
          }
          console.log('status data -> -----', this.status);

          this.status.area = areaArray.length > 0 ? areaArray : null;
          this.timer = setInterval(() => {
            this.elapsedTime = DateUtil.timeStampDuring(Math.abs(
              new Date(data.updateDate || data.createDate).getTime()
              - new Date()));
          }, 1000);
        }

      })
    },
    ifrealname(info) {
      if (info) {
        this.$router.push({path: '/shopinfo'})
      } else {
        this.$message.error('还没有实名认证')
      }
    }
  },
  computed: {},
  beforeMount() {
    this.getActiveStatus()
  }
}
</script>

<style scoped lang="scss">
/deep/ {
  .shopInfoForm {
    .custom-input-width {
      width: 500px;
    }

    .auth_tips_wrap {
      display: inline-flex;
      align-items: center;
      height: 40px;
      margin-left: 20px;

      i {
        margin-right: 5px;
      }
    }

  }
}

.el-card {
  /deep/ {
    .el-card__body {
      padding: 12px;
    }
  }

  margin-bottom: 20px;
  border-radius: unset;
}

.card-content-text {
  padding-right: 40px;
  line-height: 40px;
}

.card {
  padding: 12px 15px;
  text-align: center;
  background-color: #F8F8F8;

  h1 {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}
</style>
